import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessToken } from '@app-graphql';
import { isPlatformBrowser } from '@angular/common';

const TOKEN = 'token';

export interface LocalAuthPayload {
    token: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class TokensService {
    auth: BehaviorSubject<LocalAuthPayload | null>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            const initialPayload: LocalAuthPayload = {
                token: localStorage.getItem(TOKEN),
            };

            this.auth = new BehaviorSubject<LocalAuthPayload | null>(
                initialPayload.token ? initialPayload : null,
            );
        }
    }

    setTokens(payload: AccessToken | null) {
        if (isPlatformBrowser(this.platformId)) {
            if (! payload) {
                localStorage.setItem(TOKEN, '');
                this.auth.next(null);
            } else {
                const localPayload: LocalAuthPayload = {
                    ...payload,
                };
                localStorage.setItem(TOKEN, payload.token!);
                this.auth.next(localPayload);
            }
        }
    }
}
