import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'

@Injectable()
export class WindowRef {
    public readonly nativeWindow: Window;

    constructor(
        @Inject(DOCUMENT)
        document: Document,
    ) {
        this.nativeWindow = document.defaultView!
    }
}
