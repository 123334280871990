<h2 mat-dialog-title>
    {{ data.title  | translate}}
</h2>
<div mat-dialog-content>
    {{ data.message | translate }}
</div>
<div mat-dialog-actions>
    <button mat-raised-button
            color="accent"
            (click)="close(false)"
    >
        {{ data.buttons[0] | translate }}
    </button>
    <button color="primary"
            mat-raised-button
            (click)="close(true)"
    >
        {{ data.buttons[1] | translate }}
    </button>
</div>
