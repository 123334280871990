export function parseDate(input: string | Date): Date {
    let date: Date;

    if (typeof input === 'string') {
        date = new Date((input.length === 19 && input.includes(' ')) ? input.split(' ').join('T') : input);
    } else {
        date = input;
    }

    return date;
}
