import { isPlatformBrowser } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, Injector, NgModule, NgZone, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { ApolloClientOptions, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';

import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error'

import { AlertComponent, AlertDialogData } from '@app-components/ui/alert/alert.component';
import { environment } from '@app-environments/environment';
import { TokensService } from '@app-services/tokens/tokens.service';
import { createValidationError, ValidationError } from '@app-tools/create-validation-error';

const uri = environment.graphQLEndpoint;
const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

export function createApollo(
    tokens: TokensService,
    router: Router,
    dialog: MatDialog,
    injector: Injector,
    zone: NgZone,
    httpLink: HttpLink,
    transferState: TransferState,
    cache: InMemoryCache,
): ApolloClientOptions<NormalizedCacheObject> {
    const platformId = injector.get(PLATFORM_ID);

    const errorHandler = onError(({ response, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach((
                {
                    message,
                    locations,
                    path,
                },
            ) => {
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
            });
            if (response && response.errors) {
                if (! response.extensions) {
                    response.extensions = {};
                }
                response.extensions.validationErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'validation') {
                        acc.push(...createValidationError(err));
                    }
                    return acc;
                }, [] as Array<ValidationError>);
                const hasServerErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'internal') {
                        return true;
                    }
                    return acc;
                }, false);

                if (hasServerErrors && isPlatformBrowser(platformId)) {
                    dialog.open(AlertComponent, {
                        data: {
                            title: 'Er is iets fout gegaan',
                            message: 'Er is een probleem met de server, probeer het later opnieuw',
                        } as AlertDialogData,
                    });
                }
            }
        }
        if (networkError && isPlatformBrowser(platformId)) {
            console.error(`[Network error]: ${networkError}`);
        }
    });

    const authLink = new ApolloLink((operation, forward) => {
        let context = operation.getContext();
        const currentTokens = tokens.auth?.getValue();
        context = {
            ...context,
            headers: {
                ...context.headers,
                authorization: currentTokens && currentTokens.token ? `Bearer ${currentTokens.token}` : '',
            },
        };
        operation.setContext(context);
        return forward(operation);
    });

    const isBrowser = transferState.hasKey<any>(STATE_KEY);

    if (isBrowser) {
        const state = transferState.get<any>(STATE_KEY, null);
        cache.restore(state);
    } else {
        transferState.onSerialize(STATE_KEY, () => cache.extract());
    }

    return {
        link: ApolloLink.from([
            (errorHandler as unknown as ApolloLink),
            authLink,
            httpLink.create({
                uri: (operation) => (environment.production ? uri : `${uri}?op=${operation.operationName}`),
            }),
        ]),
        cache,
        ...(isBrowser
            ? {
                // queries with `forceFetch` enabled will be delayed
                ssrForceFetchDelay: 200,
            }
            : {
                // avoid to run twice queries with `forceFetch` enabled
                ssrMode: true,
            }),
        defaultOptions: {
            mutate: {
                errorPolicy: 'all',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    };
}

@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        Apollo,
        {
            provide: APOLLO_CACHE,
            useValue: new InMemoryCache(),
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                TokensService,
                Router,
                MatDialog,
                Injector,
                NgZone,
                HttpLink,
                TransferState,
                APOLLO_CACHE,
            ],
        },
    ],
})
export class GraphQLModule {
}
