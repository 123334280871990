import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'over-ons',
        loadChildren: () => import('./pages/about-us/about-us.module').then((m) => m.AboutUsModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'grant-booking-proposition',
        loadChildren: () => import('./pages/grant-booking/grant-booking.module').then((m) => m.GrantBookingModule),
    },
    {
        path: 'decline-booking-proposition',
        loadChildren: () => import('./pages/decline-booking/decline-booking.module')
            .then((m) => m.DeclineBookingModule),
    },
    {
        path: 'training-plannen',
        loadChildren: () => import('./pages/booking-proposition/booking-proposition.module').then(
            (m) => m.BookingPropositionModule,
        ),
    },
    {
        path: 'inschrijven',
        loadChildren: () => import('./pages/booking/booking.module').then((m) => m.BookingModule),
    },
    {
        path: 'inschrijven/:position',
        loadChildren: () => import('./pages/booking/booking.module').then((m) => m.BookingModule),
    },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule),
    },
    {
        path: 'actueel',
        loadChildren: () => import('./pages/news/news.module').then((m) => m.NewsModule),
    },
    {
        path: 'locaties',
        loadChildren: () => import('./pages/locations/locations.module').then((m) => m.LocationsModule),
    },
    {
        path: 'training',
        loadChildren: () => import('./pages/training/training.module').then((m) => m.TrainingModule),
    },
    {
        path: 'pagina/:slug',
        loadChildren: () => import('./pages/custom/custom.module').then((m) => m.CustomPageModule),
    },
    {
        path: 'maatwerk',
        loadChildren: () => import('./pages/quotation/quotation.module').then((m) => m.QuotationModule),
    },
    {
        path: 'niet-gevonden',
        loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule),
    },
    {
        path: ':slug',
        loadChildren: () => import('./pages/custom/custom.module').then((m) => m.CustomPageModule),
    },
    {
        path: '**',
        redirectTo: 'niet-gevonden',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
            onSameUrlNavigation: 'reload',
        })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
