import { GraphQLError } from 'graphql';

export interface ValidationError {
    field: string;
    message: Array<string>;
}

export const createValidationError = (error: GraphQLError): Array<ValidationError> => {
    const entries = Object.entries(error.extensions?.validation);
    return entries.map(([field, message]) => ({
        field: field.replace(/^input\./g, ''),
        message,
    } as ValidationError));
};
