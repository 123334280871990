import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@app-components/ui/confirm-dialog/confirm-dialog.component';

export interface ComponentCanDeactivate {
    canDeactivate(): boolean | Observable<boolean>;
    onDeactivate?(status: boolean): void;
}

@Injectable()
export class UnsavedChangesGuard  {
    constructor(private dialog: MatDialog) {
    }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        if (! component.canDeactivate) {
            return true;
        }
        if (component.canDeactivate()) {
            return true;
        }
        const dialog = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: 'Let op!',
                message: 'U heeft niet-opgeslagen wijzigingen. Druk op Annuleren om terug te gaan en deze '
                    + 'wijzigingen op te slaan of op OK om deze wijzigingen te verwijderen.',
                buttons: [
                    'Annuleren',
                    'Ok',
                ],
            },
        });
        return dialog.afterClosed().pipe(
            tap((status) => {
                if (component.onDeactivate) {
                    component.onDeactivate(status);
                }
            }),
        );
    }
}
