import { Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { CookieConsentService } from '@app-services/cookie-consent/cookie-consent.service';
import { isPlatformBrowser } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    title = 'Website';

    private popupOpenSubscription: Subscription;
    private statusChangeSubscription: Subscription;

    constructor(
        private translate: TranslateService,
        private ccService: NgcCookieConsentService,
        private cookieConsentService: CookieConsentService,
        @Inject(PLATFORM_ID) private platformId: any,
        private gtmService: GoogleTagManagerService,
        private router: Router,
    ) {
        this.initializeWebsite();
    }

    private async initializeWebsite() {

        this.translate.setDefaultLang('nl');
        if (isPlatformBrowser(this.platformId)) {

            await this.gtmService.addGtmToDom();
            await this.router.events.forEach((item) => {
                if (item instanceof NavigationEnd) {
                    const gtmTag = {
                        event: 'page',
                        pageName: item.url,
                    };
                    this.gtmService.pushTag(gtmTag);
                }
            });

            this.popupOpenSubscription = this.ccService.popupOpen$.subscribe();

            this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
                (event: NgcStatusChangeEvent) => {
                    this.cookieConsentService.setConsentCookie(event.status);
                },
            );
        }
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            this.popupOpenSubscription.unsubscribe();
            this.statusChangeSubscription.unsubscribe();
        }
    }

}
