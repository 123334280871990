import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
    title: string;
    message: string;
    buttons: [string, string];
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        private matDialog: MatDialogRef<ConfirmDialogComponent>,
    ) {
    }

    close(b: boolean) {
        this.matDialog.close(b);
    }
}
