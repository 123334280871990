import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {

    constructor(
        private cookieService: CookieService,
    ) {
    }

    isCookieConsentGiven() {
        return this.cookieService.get('cookieconsent_status') === 'allow';
    }

    removeCookies() {
        this.cookieService.deleteAll('/', '.youtube.com');
    }

    setConsentCookie(status: string) {
        this.cookieService.set('cookieconsent_status', status, 365, '/', 'g4strainingen.nl');
    }
}
