import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const checkEmail = (control: AbstractControl): ValidationErrors | null => {
    const { value } = control;
    if (value) {
        const valid = (/^([A-Za-z0-9_\-.])+((\+([A-Za-z0-9_\-.])+)?)@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/).test(value);
        if (! valid) {
            return { emailNotValid: 'emailNotValid' };
        }
        return null;
    }
    return null;
};

export const matchPassword = (group: UntypedFormGroup): ValidationErrors | null => {
    if (group && group.controls.password.value && group.controls.confirmPassword.value) {
        if (group.controls.password.value !== group.controls.confirmPassword.value) {
            return { noMatchingPassword: true };
        }
        return null;
    }
    return null;
};

export const matchPasswords: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const newPassword = control.get('newPassword');
    const confirmPassword = control.get('confirmPassword');

    return newPassword && confirmPassword && newPassword.value
    === confirmPassword.value ? null : { noMatchingPassword: true };
};

export function fieldsEqual(field1: string, field2: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const control1 = control.get(field1);
        const control2 = control.get(field2);

        if (control1 && control2) {
            if (control1.value === control2.value) {
                return null;
            }
            control2.setErrors({ fieldsEqual: true });
            return { fieldsEqual: true };
        }
        return { fieldsEqual: true };
    };
}
