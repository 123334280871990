import { APP_ID, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core'
import { BrowserModule, provideClientHydration } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { WindowRef } from '@app-loaders/ref-loaders/window.loader';
import { translateBrowserLoaderFactory } from '@app-loaders/translate-browser.loader';
import { GraphQLModule } from '@app-modules/graphql/graphql.module';
import { CustomMatPaginatorIntl } from '@app-helpers';
import { UnsavedChangesGuard } from '@app-guards/unsaved-changes/unsaved-changes.guard';

import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '@app-environments/environment';
import { cookieConfig, sharedProviders } from './app.config'



Bugsnag.start({
    apiKey: 'eaab67fdc34b7fb993b66501cb8ce004',
});

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatSnackBarModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        GraphQLModule,
        MatNativeDateModule,
        MatDialogModule,
        RecaptchaV3Module,
    ],
    providers: [
        ...sharedProviders,
        WindowRef,
        UnsavedChangesGuard,
        CookieService,
        provideClientHydration(),
    ],
})

export class AppModule {
}
