import { isPlatformBrowser } from '@angular/common'
import { APP_ID, ErrorHandler, inject, PLATFORM_ID } from '@angular/core'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { environment } from '@app-environments/environment'
import { CustomMatPaginatorIntl } from '@app-helpers'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha'
import { NgcCookieConsentConfig } from 'ngx-cookieconsent'

export const DateFormat = {
    parse: {
        dateInput: 'input',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: 'g4strainingen.nl',
    },
    content: {
        message: 'Deze website slaat gegevens op zoals cookies om essentiële sitefunctionaliteit mogelijk te maken,'
            + ' evenals marketing, personalisatie en analyse. '
            + 'U kunt uw instellingen op elk moment wijzigen of de standaardinstellingen accepteren.',
        allow: 'Cookies toestaan',
        deny: 'Afwijzen',
        href: '/pagina/cookiebeleid',
        target: '_blank',
        link: 'Cookie beleid',
        policy: 'Cookies',
    },
    palette: {
        popup: {
            background: '#db373d',
        },
        button: {
            background: '#10253f',
        },
    },
    theme: 'block',
    type: 'opt-in',
};

export function errorHandlerFactory() {
    const platformId = inject(PLATFORM_ID)
    if (isPlatformBrowser(platformId)) {
        return window.location.host.indexOf('localhost') === -1
            ? new BugsnagErrorHandler()
            : new ErrorHandler();
    }

    return new BugsnagErrorHandler();
}

/**
 * Shared provider array for configuration that applies to
 * both the server and browser rendering environments.
 */
export const sharedProviders = <const>[
    {
        provide: APP_ID,
        useValue: 'Website',
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: DateFormat,
    },
    {
        provide: MAT_DATE_LOCALE,
        useValue: 'nl',
    },
    {
        provide: MatPaginatorIntl,
        useClass: CustomMatPaginatorIntl,
    },
    isPlatformBrowser(PLATFORM_ID)
        ? [ {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        } ]
        : [],
    {
        provide: 'googleTagManagerId',
        useValue: 'GTM-PM4WZC3',
    },
    {
        provide: RECAPTCHA_V3_SITE_KEY,
        useValue: environment.recaptcha,
    },
]
